import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import PanoramaRoundedIcon from "@material-ui/icons/PanoramaRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import "./ImageInput.scss";

function ImageInput({ file, addFile }) {
  const [preview, setPreview] = useState("");

  const dropzoneRef = useRef(null);

  useEffect(() => {
    dropzoneRef?.current.focus();
  }, []);

  const removeFiles = () => {
    addFile(null);
    URL.revokeObjectURL(file);
    setPreview("");
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    addFile(file);
    const imageUrl = URL.createObjectURL(file);
    setPreview(imageUrl);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop,
    multiple: false,
  });

  const InputFilePrompt = ({ isDragActive }) => (
    <div
      className={`ImageInput__prompt ${
        isDragActive ? "ImageInput__prompt--dragActive" : ""
      }`}
    >
      <PanoramaRoundedIcon />
      {isDragActive ? (
        <p className="ImageInput__promptText">Drop the files here ...</p>
      ) : (
        <p className="ImageInput__promptText">
          Tap to add cover image
          <span className="ImageInput__promptWebOnly">
            <br />
            or drag a file here
          </span>
        </p>
      )}
    </div>
  );

  const ImagePreview = () => (
    <>
      <div className="ImageInput__previewReset" onClick={removeFiles}>
        <CloseRoundedIcon color="#ffffff" />
      </div>
      <img
        className="ImageInput__previewImg"
        src={preview}
        alt="Article Cover image"
      />
    </>
  );

  return (
    <div className="ImageInput" {...getRootProps()} ref={dropzoneRef}>
      <input {...getInputProps()} />
      <>
        {file ? (
          <ImagePreview />
        ) : (
          <InputFilePrompt isDragActive={isDragActive} />
        )}
      </>
    </div>
  );
}

export default ImageInput;
