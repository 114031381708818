import React from "react";
import { Link } from "react-router-dom";
import SubSection from "../../../components/WrtiteUp/sub-components/SubSection/SubSection";
import WrtiteUp from "../../../components/WrtiteUp/WrtiteUp";

import "./About.scss";

function About() {
  return (
    <div className="About">
      <WrtiteUp title="Contact">
        <SubSection>
          <p className="WriteUp__paragraph">
            Riple is a free social news platform that allows users to share
            short news stories of up to 100 words. The mission of the company is
            to collect and broadcast news from users all over the world that the
            mainstream media chooses to ignore, directly from their mobile
            phones. Riple enables everyone to be a journalist and encourages
            people to speak up and share their narrative through news snippets.
          </p>
        </SubSection>
        <SubSection className="Contact Us">
          <p className="WriteUp__paragraph">
            If you have any questions about this{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>, you can contact
            us:
          </p>
          <li className="WriteUp__bullets">By email: Riple@outlook.com</li>
          <li className="WriteUp__bullets">
            By phone number: +1(617) 336-8954
          </li>
        </SubSection>
      </WrtiteUp>
    </div>
  );
}

export default About;
