import React, { useCallback, useEffect, useState } from "react";
import reactDom from "react-dom";
import CloseIcon from "@material-ui/icons/Close";

import Button from "../Button/Button";

import "./Modal.scss";

export const ModalFooter = ({ children }) => (
  <div className="Modal__footer">{children}</div>
);

export const ModalBody = ({ children }) => (
  <div className="Modal__body">{children}</div>
);

function Modal({ open, children, close, title, action, hideClose }) {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    close();
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    // setTimeout(() => setisModalEntering(false), 3000);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  if (!open) return null;
  return reactDom.createPortal(
    <div className="overlay">
      <div className="Modal">
        {!hideClose && (
          <div className="Modal__closeButton" onClick={() => closeModal()}>
            <CloseIcon />
          </div>
        )}
        <div className="Modal__title">
          <h1>{title}</h1>
        </div>
        {children}
      </div>
    </div>,
    document.getElementById("portal")
  );
}

export default Modal;
