import React, { useEffect, useState } from "react";
import { endPoints } from "../../../config/api";
import { useStateValue } from "../../../data_layer/store";
import { doGet } from "../../../services/apiCalls";
import Articles from "../../../components/Articles/Articles";
import Skeleton from "@material-ui/lab/Skeleton";

import "./Profile.scss";
import Avatar from "../../../components/Avatar/Avatar";
import { useParams } from "react-router-dom";
import PostSkeleton from "../../../components/skeletons/PostSkeleton/PostSkeleton";

function Profile() {
  const [{ user }, dispatch] = useStateValue();
  const [articles, setArticles] = useState([]);
  const [publisher, setPublisher] = useState(null);
  const { userId } = useParams();

  const setAppLoading = (loadingState) => {
    dispatch({
      type: "SET_LOADING",
      payload: loadingState,
    });
  };

  useEffect(() => {
    setAppLoading(true);
    doGet(endPoints.getUser, { params: { all: true, userId } }).then(
      ({ data }) => {
        setArticles(data?.user?.articles);
        setPublisher({
          name: data?.user?.name,
          profilePic: data?.user?.profilePic,
        });
        setAppLoading(false);
      }
    );
  }, []);

  return (
    <div className="Profile">
      <div className="Profile__user">
        <Avatar imgSrc={publisher?.profilePic} />
        <h4 className="Profile__userName">
          {publisher ? (
            publisher?.name
          ) : (
            <Skeleton animation="wave" variant="text" width="15ch" />
          )}
        </h4>
      </div>
      <Articles articles={articles} />
    </div>
  );
}

export default Profile;
