import React from "react";
import SubSection from "../../../components/WrtiteUp/sub-components/SubSection/SubSection";
import WrtiteUp from "../../../components/WrtiteUp/WrtiteUp";

import "./PrivacyPolicy.scss";

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      <WrtiteUp title="Privacy Policy">
        <SubSection title="">
          <p className="WriteUp__paragraph">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy.
          </p>
        </SubSection>
        <br />
        <br />
        <h3>Interpretation and Definitions</h3>
        <br />
        <br />
        <SubSection title="Interpretation">
          <p className="WriteUp__paragraph">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
        </SubSection>
        <SubSection title="Definitions">
          <p className="WriteUp__paragraph">
            For the purposes of this Privacy Policy:
          </p>
          <ul>
            <li className="WriteUp__bullets">
              Account means a unique account created for You to access our
              Service or parts of our Service.
            </li>
            <li className="WriteUp__bullets">
              Affiliate means an entity that controls, is controlled by or is
              under common control with a party, where "control" means ownership
              of 50% or more of the shares, equity interest or other securities
              entitled to vote for election of directors or other managing
              authority
            </li>
            <li className="WriteUp__bullets">
              Application means the software program provided by the Company
              downloaded by You on any electronic device, named riple
            </li>
            <li className="WriteUp__bullets">
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to riple Inc.
            </li>
            <li className="WriteUp__bullets">
              Country refers to: United Kingdom
            </li>
            <li className="WriteUp__bullets">
              Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet
            </li>
            <li className="WriteUp__bullets">
              Personal Data is any information that relates to an identified or
              identifiable individual.
            </li>
            <li className="WriteUp__bullets">
              Service refers to the Application
            </li>
            <li className="WriteUp__bullets">
              Service Provider means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to
              perform services related to the Service or to assist the Company
              in analysing how the Service is used.
            </li>
            <li className="WriteUp__bullets">
              Third-party Social Media Service refers to any website or any
              social network website through which a User can log in or create
              an account to use the Service.
            </li>
            <li className="WriteUp__bullets">
              Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
            <li className="WriteUp__bullets">
              You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicabl
            </li>
          </ul>
        </SubSection>
        <br />
        <br />
        <h3>Collecting and Using Your Personal Data</h3>
        <br />
        <br />
        <h4>Types of Data Collected</h4>
        <br />
        <br />
        <SubSection title="Personal Data">
          <p className="WriteUp__paragraph">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <li className="WriteUp__bullets">Email address</li>
          <li className="WriteUp__bullets">First name and last name</li>
          <li className="WriteUp__bullets">Usage Data</li>
        </SubSection>
        <SubSection title="Usage Data">
          <p className="WriteUp__paragraph">
            Usage Data is collected automatically when using the Service.
          </p>
          <p className="WriteUp__paragraph">
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p className="WriteUp__paragraph">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p className="WriteUp__paragraph">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
        </SubSection>
        <SubSection title="Information from Third-Party Social Media Services">
          <p className="WriteUp__paragraph">
            The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media Services:
          </p>
          <li className="WriteUp__bullets">Google</li>
          <li className="WriteUp__bullets">Facebook</li>
          <li className="WriteUp__bullets">Twitter</li>
          <p className="WriteUp__paragraph">
            If You decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media Service's
            account, such as Your name, Your email address, Your activities or
            Your contact list associated with that account.{" "}
          </p>
          <p className="WriteUp__paragraph">
            You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service's account.
            If You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy.
          </p>
        </SubSection>

        <SubSection title="Information Collected while Using the Application">
          <p className="WriteUp__paragraph">
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </p>
          <li className="WriteUp__bullets">
            Information regarding your location
          </li>
          <li className="WriteUp__bullets">
            Pictures and other information from your Device's camera and photo
            library
          </li>
          <p className="WriteUp__paragraph">
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or it
            may be simply stored on Your device.:
          </p>
          <p className="WriteUp__paragraph">
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </p>
        </SubSection>
        <SubSection title="Use of Your Personal Data">
          <p className="WriteUp__paragraph">
            The Company may use Personal Data for the following purposes:
          </p>

          <li className="WriteUp__bullets">
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </li>
          <li className="WriteUp__bullets">
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </li>
          <li className="WriteUp__bullets">
            For the performance of a contract: the development, compliance and
            undertaking of the purchase contract for the products, items or
            services You have purchased or of any other contract with Us through
            the Service.
          </li>
          <li className="WriteUp__bullets">
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </li>
          <li className="WriteUp__bullets">
            To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </li>
          <li className="WriteUp__bullets">
            To manage Your requests: To attend and manage Your requests to Us.
          </li>
          <li className="WriteUp__bullets">
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </li>
          <li className="WriteUp__bullets">
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your
            experience.
          </li>

          <p className="WriteUp__paragraph">
            We may share Your personal information in the following situations:
          </p>

          <li className="WriteUp__bullets">
            With Service Providers: We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, to
            contact You.
          </li>
          <li className="WriteUp__bullets">
            For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </li>
          <li className="WriteUp__bullets">
            With Affiliates: We may share Your information with Our affiliates,
            in which case we will require those affiliates to honor this Privacy
            Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners or other companies that We
            control or that are under common control with Us.
          </li>
          <li className="WriteUp__bullets">
            With business partners: We may share Your information with Our
            business partners to offer You certain products, services or
            promotions.
          </li>
          <li className="WriteUp__bullets">
            With other users: when You share personal information or otherwise
            interact in the public areas with other users, such information may
            be viewed by all users and may be publicly distributed outside. If
            You interact with other users or register through a Third-Party
            Social Media Service, Your contacts on the Third-Party Social Media
            Service may see Your name, profile, pictures and description of Your
            activity. Similarly, other users will be able to view descriptions
            of Your activity, communicate with You and view Your profile.
          </li>
          <li className="WriteUp__bullets">
            With Your consent: We may disclose Your personal information for any
            other purpose with Your consent.
          </li>
        </SubSection>

        <SubSection title="Retention of Your Personal Data">
          <p className="WriteUp__paragraph">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p className="WriteUp__paragraph">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
        </SubSection>

        <SubSection title="Transfer of Your Personal Data">
          <p className="WriteUp__paragraph">
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p className="WriteUp__paragraph">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="WriteUp__paragraph">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
        </SubSection>
      </WrtiteUp>
    </div>
  );
}

export default PrivacyPolicy;
