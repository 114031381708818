import React, { useState } from "react";
import reactDom from "react-dom";
import PageTitle from "../../../../../../../components/PageTitle/PageTitle";

import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import "./Comments.scss";

import Skeleton from "@material-ui/lab/Skeleton";
import EmptyScreen from "../../../../../../../components/EmptyScreen/EmptyScreen";
import { doPost } from "../../../../../../../services/apiCalls";
import { endPoints } from "../../../../../../../config/api";

const Comment = ({ comment }) => (
  <div className="Comment">
    <div className="Comment__avatar"></div>
    <div className="Comment__content">
      <h5 className="Comment__ownerName">{comment?.owner?.name}</h5>
      <p className="Comment__comment">{comment?.comment}</p>
    </div>
  </div>
);

const LoadingComment = () => (
  <div className="Comment">
    <div className="Comment__avatar">
      <Skeleton variant="circle" width={"100%"} height={"100%"} />
    </div>
    <div className="Comment__content">
      <Skeleton variant="text" width={"60%"} />
      <br />
      <Skeleton variant="text" width={"90%"} />
      <Skeleton variant="text" width={"70%"} />
    </div>
  </div>
);

const CommentForm = ({ onSubmit, comment, setComment }) => {
  return (
    <div className="CommentForm">
      <form onSubmit={onSubmit}>
        <input
          type="text"
          placeholder="Write a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </form>
    </div>
  );
};

function Comments({ show, comments, onClose, loading, user, articleId }) {
  const [comment, setComment] = useState("");

  const sendComment = (e) => {
    e.preventDefault();
    const payload = {
      userId: user._id,
      articleId,
      comment,
    };
    comments.push({ owner: user.owner, comment: comment });
    setComment("");

    doPost(endPoints.postComment, payload);
  };

  return reactDom.createPortal(
    show ? (
      <div
        className={`Comments__overlay ${
          show ? "" : " Comments__overlay--closed"
        }`}
      >
        <div className="Comments__clickAway" onClick={onClose}></div>
        <div className={`Comments ${show ? "" : " Comments--closed"}`}>
          <div className="Comments__header" onClick={onClose}>
            <PageTitle title="Comment" /> <KeyboardArrowDownRoundedIcon />
          </div>

          <div className="Comment__wrap">
            {comments?.length !== 0 ? (
              !loading ? (
                comments?.map((comment, idx) => (
                  <Comment key={idx} comment={comment} />
                ))
              ) : (
                <LoadingComment />
              )
            ) : (
              <EmptyScreen />
            )}
          </div>
          <CommentForm
            comment={comment}
            setComment={setComment}
            onSubmit={sendComment}
          />
        </div>
      </div>
    ) : null,
    document.getElementById("portal")
  );
}

export default Comments;
