// import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Button from "../../../components/Button/Button";
import { CardView } from "../../../components/CardScollView/CardScollView";
import NewsCard from "../../../components/NewsCard/NewsCard";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { endPoints } from "../../../config/api";
import { useStateValue } from "../../../data_layer/store";
import { doGet, doPost } from "../../../services/apiCalls";
import GetConfirmationModal from "../../../components/modals/GetConfirmation/GetConfirmation";

// import uploadToImgur from "../../../factory/Imgur";

import "./AddArticle.scss";
import ImageInput from "./sub-components/ImageInput";
import { useHistory } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";

function AddArticle() {
  const [{ user }, dispatch] = useStateValue();
  const [topics, setTopics] = useState(["Loading topics..."]);
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [image, setimage] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [articleBody, setArticleBody] = useState("");

  const [wordCount, setWordCount] = useState(0);
  const MAX_WORDS_BODY = 60;

  const [isModalOpen, setIsModalOpen] = useState(!user.userVerified);

  const [preview, setPreview] = useState(null);

  const closeModal = () => {
    history.push("/news");
    setIsModalOpen(false);
  };

  const confirmAction = () => {
    console.log({ email: user.email });
    doGet(endPoints.getConfirmation, { params: { email: user.email } })
      .then((res) => {
        if (!res) return toast("Login again. To use this feature");
        const { data } = res;

        toast.success(data?.message);
      })
      .finally(() => closeModal());
  };

  const setAppLoading = (loadingState) => {
    dispatch({
      type: "SET_LOADING",
      payload: loadingState,
    });
  };

  useEffect(() => {
    setAppLoading(true);
    doGet(endPoints.getTopics)
      .then((res) => {
        if (!res) return;
        const {
          data: { topics },
        } = res;
        setTopics(topics);
      })
      .finally(() => setAppLoading(false));

    return () => {
      // cleanup
    };
  }, []);

  const handleArticleBody = (e) => {
    const textVal = e.target.value;
    const trimmedVal = textVal.trim();
    const wordCount = trimmedVal === "" ? 0 : trimmedVal.split(" ").length;
    setWordCount(wordCount);
    setArticleBody(textVal);
  };

  const clearFields = () => {
    setArticleBody("");
    setTitle("");
    setSelectedTopic("");
    setimage(null);
  };

  const onSubmitArticle = async () => {
    setAppLoading(true);
    const toastId = "add-article";

    const errors = [];

    if (!title) errors.push("Title is required");
    if (!image) errors.push("Image is required");
    if (!articleBody) errors.push("Body insertion is required");
    if (!selectedTopic) errors.push("Category is required");

    if (errors.length) {
      setAppLoading(false);
      return toast.error(errors[0], { id: toastId });
    }

    const fd = new FormData();
    fd.append("image", image);
    fd.append("title", title);
    fd.append("body", articleBody);
    fd.append("userId", user._id);
    fd.append("category", selectedTopic);

    const objForm = {
      title,
      body: articleBody,
      coverImg: URL.createObjectURL(image),
      owner: user,
    };

    setPreview(objForm);

    const response = await doPost(endPoints.postArticle, fd, {
      toastId,
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (!response?.data?.success) return setAppLoading(false);
    toast.success(response.data.message);
    setPreview(null);
    clearFields();
    setAppLoading(false);
  };

  const addFile = (file) => setimage(file);

  return (
    <Scrollbars>
      {preview ? (
        <CardView>
          <NewsCard article={preview} />
        </CardView>
      ) : (
        <div className="AddArticle">
          <PageTitle title="Add Article" />
          <input
            type="text"
            placeholder="Add headline"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <ImageInput addFile={addFile} file={image} />
          <div className="AddArticle__categorySelectWrap selectWrap">
            <select
              className="AddArticle__categorySelect"
              value={selectedTopic}
              onChange={(e) => setSelectedTopic(e.target.value)}
            >
              <option className="AddArticle__categoryOptions" value="">
                Select Topic
              </option>
              {topics.map(({ title, value }, idx) => (
                <option
                  key={idx}
                  className="AddArticle__categoryOptions"
                  value={value}
                >
                  {title}
                </option>
              ))}
            </select>
          </div>
          <textarea
            type="text"
            placeholder="Add news body..."
            value={articleBody}
            onChange={handleArticleBody}
          />
          <div
            className={`AddArticle__bodyCount ${
              wordCount > MAX_WORDS_BODY ? "AddArticle__bodyCount--error" : ""
            }`}
          >
            <p className="AddArticle__bodyCountMeter">
              {`${wordCount}/${MAX_WORDS_BODY}`}
            </p>
            {/* <CircularProgress
        variant="determinate"
        value={(wordCount / MAX_WORDS_BODY) * 100}
      /> */}
          </div>
          <Button onClick={onSubmitArticle}>Publish</Button>
        </div>
      )}
      <GetConfirmationModal
        title="Confirm Your email"
        open={isModalOpen}
        close={closeModal}
        confirm={confirmAction}
      >
        You need to confirm your email to post articles.
      </GetConfirmationModal>
    </Scrollbars>
  );
}

export default AddArticle;
