import React, { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button/Button";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { endPoints } from "../../../config/api";
import { useStateValue } from "../../../data_layer/store";
import { doPost } from "../../../services/apiCalls";
import AuthView from "../components/AuthView/AuthView";
import SwitchAuth from "../components/SwitchAuth/SwitchAuth";

import "./Login.scss";

let history;

const login = (email, password, dispatch) => {
  if (email && password) {
    const payload = { email, password };
    const toastId = "signin";
    toast.loading("Signing in", { id: toastId });

    console.log("sakdjasdba ", { payload, url: endPoints.postLogin });

    doPost(endPoints.postLogin, payload, { toastId }).then((res) => {
      if (!res) return;

      const { data } = res;

      if (!data.success)
        return toast.error("Something went wrong", { id: toastId });

      const { token, user, message } = data;
      dispatch({
        type: "SET_USER",
        payload: { ...{ token }, ...user },
      });

      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));

      toast.success(message, { id: toastId });
      history.push("/");
    });
  } else {
    toast.error("Fields cannot be empty");
  }
};

function Login() {
  history = useHistory();
  const [{}, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <AuthView>
      <div className="Login">
        <PageTitle title="Sign In" />
        <SwitchAuth
          switcherText="Don't have an account?"
          linkText="Sign Up"
          route="/auth/register"
        />
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={() => login(email, password, dispatch)}>
          Sign In
        </Button>
      </div>
    </AuthView>
  );
}

export default Login;
