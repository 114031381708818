import React from "react";
import EmptyScreen from "../EmptyScreen/EmptyScreen";
import PostSkeleton from "../skeletons/PostSkeleton/PostSkeleton";
// import StatIcon from "../StatIcon/StatIcon";

import "./Articles.scss";

const Article = ({ StatComponent, article, onClick }) => {
  const { title, coverImg } = article;

  return (
    <div className="Article" onClick={onClick}>
      <div className="Article__thumbnail">
        <img alt="" src={coverImg} />
      </div>
      <div className="Article__info">
        <h5 className="Article__headline">{title}</h5>
        {StatComponent && (
          <div className="Article__stats">
            <StatComponent />
          </div>
        )}
      </div>
    </div>
  );
};

function Articles({ articles, canDisplayEmpty, openFullView }) {
  if (!articles.length && !canDisplayEmpty)
    return [0, 0].map((_) => <PostSkeleton />);
  return (
    <div className="Articles">
      {articles.length
        ? articles?.map((article, idx) => (
            <Article
              key={idx}
              article={article}
              onClick={() => openFullView?.(idx)}
            />
          ))
        : canDisplayEmpty && (
            <div className="Articles__empty">
              <EmptyScreen />
            </div>
          )}
    </div>
  );
}

export default Articles;
