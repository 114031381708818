import React from "react";

// styles
import "./PageTitle.scss";

function PageTitle({ title }) {
  return (
    <div className="PageTitle">
      <h1>{title}</h1>
    </div>
  );
}

export default PageTitle;
