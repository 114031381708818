import React from "react";

import "./Avatar.scss";

function Avatar({ imgSrc }) {
  return (
    <div className="Avatar">
      <img src={imgSrc || "/profPic.png"} alt="" />
    </div>
  );
}

export default Avatar;
