import React from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";

import CategoriesSection from "./sub-components/CategoriesSection";

// styles
import "./SearchPage.scss";
import TopicSection from "./sub-components/TopicSection";
import Scrollbar from "../../../util-components/Scrollbar";

function SearchPage() {
  return (
    <Scrollbar>
      <div className="SearchPage">
        <PageTitle title={"Search"} />
        <input type="text" placeholder="Search Riple" />
        {/* <CategoriesSection /> */}
        <TopicSection />
      </div>
    </Scrollbar>
  );
}

export default SearchPage;
