import React from "react";
import Button from "../../Button/Button";
import Modal, { ModalBody, ModalFooter } from "../../Modal/Modal";

function GetConfirmationModal({
  open,
  close,
  title,
  children,
  confirm,
  confirmText,
  hideClose,
}) {
  return (
    <Modal open={open} close={close} title={title} hideClose>
      <div className="GetConfirmationModal">
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button appearance="secondary" onClick={close}>
            Cancel
          </Button>
          <Button onClick={confirm}>{confirmText || "OK"}</Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

export default GetConfirmationModal;
