import React from "react";
import Scrollbars from "react-custom-scrollbars";

function Scrollbar({ children, style }) {
  return (
    <Scrollbars
      style={style}
      autoHide
      autoHideTimeout={500}
      autoHideDuration={300}
    >
      {children}
    </Scrollbars>
  );
}

export default Scrollbar;
