import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

import "./PostSkeleton.scss";

function PostSkeleton() {
  const isBigDevice = window.matchMedia("(min-width: 500px)").matches;

  return (
    <div className="PostSkeleton">
      <Skeleton
        animation="wave"
        width={isBigDevice ? "15.5rem" : "10.5rem"}
        height={isBigDevice ? "12.5rem" : "7rem"}
      />
      <div>
        <Skeleton animation="wave" variant="text" width="18ch" />
        <Skeleton animation="wave" variant="text" width="12ch" />
      </div>
    </div>
  );
}

export default PostSkeleton;
