import React, { useEffect, useState } from "react";
import CardScollView, {
  CardView,
} from "../../../components/CardScollView/CardScollView";
import Header from "../../../components/Header/Header";
import NewsCard from "../../../components/NewsCard/NewsCard";
import { endPoints } from "../../../config/api";
import { useStateValue } from "../../../data_layer/store";
import { doGet } from "../../../services/apiCalls";

import "./Homepage.scss";
import InteractionPanel from "./sub-components/InteractionPanel/InteractionPanel";

import EmptyScreen from "../../../components/EmptyScreen/EmptyScreen";
import NewsCardSkeleton from "../../../components/skeletons/NewsCardSkeleton/NewsCardSkeleton";
import { useParams } from "react-router-dom";

function Homepage() {
  const [{ user, loading }, dispatch] = useStateValue();
  const [articles, setArticles] = useState([]);
  const [requestedArticle, setRequestedArticle] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const { newsId } = useParams();

  const setAppLoading = (loadingState) => {
    dispatch({
      type: "SET_LOADING",
      payload: loadingState,
    });
  };

  const toggleHeader = () => setShowHeader(!showHeader);

  const closeHeader = () => setShowHeader(false);

  useEffect(() => {
    setAppLoading(true);

    if (newsId) {
      doGet(`${endPoints.getArticle}/${newsId}`).then((res) => {
        if (res) {
          const {
            data: { article: fetchedArticle },
          } = res;
          setRequestedArticle(fetchedArticle);
        }
      });
    }
    doGet(endPoints.getArticles)
      .then((res) => {
        if (!res) return;
        const { data } = res;
        const { articles: fetchedArticles } = data;
        setArticles(fetchedArticles.filter(({ _id }) => _id !== newsId));
      })
      .finally(() => setAppLoading(false));

    return () => {
      // cleanup
    };
  }, []);

  return (
    <div className="Homepage">
      <div
        style={{
          opacity: `${showHeader ? 1 : 0}`,
          height: `${showHeader ? "4rem" : "0"}`,
          transition: "all 300ms ease-in-out",
        }}
      >
        <Header />
      </div>
      <CardScollView
        isArticles={articles.length}
        isShowHeader={showHeader}
        hideHeader={closeHeader}
      >
        {!loading && requestedArticle && (
          <CardView key={-1}>
            <NewsCard article={requestedArticle} onClick={toggleHeader} />
            <InteractionPanel article={requestedArticle} userId={user?._id} />
          </CardView>
        )}
        {!loading &&
          articles?.map((article, idx) => (
            <CardView key={idx}>
              <NewsCard article={article} onClick={toggleHeader} />
              <InteractionPanel article={article} userId={user?._id} />
            </CardView>
          ))}

        {loading && <NewsCardSkeleton />}

        {!loading && !articles.length && <EmptyScreen />}
      </CardScollView>
    </div>
  );
}

export default Homepage;
