import React from "react";

import "./SubSection.scss";

function SubSection({ title, children }) {
  return (
    <div className="SubSection">
      <h5 className="SubSection__title">{title}</h5>
      <p className="SubSection__body">{children}</p>
    </div>
  );
}

export default SubSection;
