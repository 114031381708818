import React from "react";

import "./Tabs.scss";

const Tab = ({ title, isActive, onClick, idx }) => (
  <div
    className={`Tab${isActive ? " Tab--active" : ""}`}
    onClick={() => onClick?.(idx)}
  >
    {title}
  </div>
);

function Tabs({ activeTabIdx, controller, tabItems }) {
  return (
    <div className="Tabs">
      {tabItems.map(({ title }, idx) => (
        <Tab
          key={idx}
          idx={idx}
          title={title}
          isActive={activeTabIdx === idx}
          onClick={controller}
        />
      ))}
    </div>
  );
}

export default Tabs;
