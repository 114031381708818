import React from "react";
import PageTitle from "../PageTitle/PageTitle";

import "./WrtiteUp.scss";

function WrtiteUp({ children, title }) {
  return (
    <div className="WrtiteUp">
      <PageTitle title={title} />
      {children}
    </div>
  );
}

export default WrtiteUp;
