import React from "react";
import { Link } from "react-router-dom";
import "./SwitchAuth.scss";

function SwitchAuth({ switcherText, linkText, route }) {
  return (
    <div className="SwitchAuth">
      <p className="SwitchAuth__text">{switcherText}</p>
      <Link to={route}>{linkText}</Link>
    </div>
  );
}

export default SwitchAuth;
