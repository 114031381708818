const BASE_ADDRESS = "https://api.riple.org";
// const BASE_ADDRESS = "http://localhost:5000";

const API_LEVEL = "v0";

export const BASE_URL = `${BASE_ADDRESS}/${API_LEVEL}`;

export const routes = {
  auth: `${BASE_URL}/auth`,
  user: `${BASE_URL}/user`,
  article: `${BASE_URL}/article`,
  comment: `${BASE_URL}/comment`,
  topics: `${BASE_URL}/topics`,
};

export const endPoints = {
  postRegister: `${routes.auth}/register`,
  postLogin: `${routes.auth}/login`,
  postConfirmation: `${routes.auth}/confirm`,
  getConfirmation: `${routes.auth}/confirm`,
  getUser: `${routes.user}`,
  getUserArticles: `${routes.user}/articles`,
  postArticle: `${routes.article}`,
  getArticles: `${routes.article}`,
  getArticle: `${routes.article}`,
  putArticle: `${routes.article}`, //likes dislikes
  getBookmarkedArticles: `${routes.article}/bookmarked`,
  postComment: `${routes.comment}`,
  getComments: `${routes.comment}`,
  getTopics: `${routes.topics}`,
};
