import React from "react";
import EmptyScreen from "../EmptyScreen/EmptyScreen";

import "./CardScollView.scss";
export const CardView = ({ children, refId }) => (
  <div className="CardView" ref={refId}>
    {children}
  </div>
);

function CardScollView({ children, isShowHeader, hideHeader }) {
  const headerClearance = isShowHeader ? 64 : 0;
  const height = window.matchMedia("(max-width: 500px)").matches
    ? window.innerHeight - 56 - headerClearance
    : `calc(100vh - ${headerClearance}px)`;
  return (
    <div
      className={`CardScollView ${
        isShowHeader ? "CardScollView--clearHeader" : ""
      }`}
      style={{ height: height }}
    >
      {children}
      {!children && (
        <CardView>
          <EmptyScreen />
        </CardView>
      )}
    </div>
  );
}

export default CardScollView;
