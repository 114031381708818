import axios from "axios";
import toast from "react-hot-toast";

const connectionMessage = "Could not connect to the internet.";

export const doPost = (url, payload, { headers, toastId }) =>
  new Promise((resolve, reject) =>
    axios
      .post(url, payload, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          ...headers,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        toast.error(err?.response?.data?.message || connectionMessage, {
          id: toastId,
        });
        resolve(null);
      })
  );

export const doGet = (url, { params, headers, toastId } = {}) =>
  new Promise((resolve, reject) =>
    axios
      .get(url, { params, headers })
      .then((res) => resolve(res))
      .catch((err) => {
        toast.error(err?.response?.data?.message || connectionMessage, {
          id: toastId,
        });
        resolve(null);
      })
  );

export const doPut = (url, payload, params, headers) =>
  new Promise((resolve, reject) =>
    axios
      .put(url, payload, { params, headers })
      .then((res) => resolve(res))
      .catch((err) => {
        toast.error(err?.response?.data?.message || connectionMessage, {
          id: "api-error",
        });
        resolve(null);
      })
  );
