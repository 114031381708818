import React from "react";

import "./EmptyScreen.scss";

function EmptyScreen({ imgSrc, message }) {
  const defaultMessage = "Oops! Nothing to show here";
  return (
    <div className="EmptyScreen">
      <img
        className="EmptyScreen__image"
        alt=""
        src={imgSrc || "/assets/doodles/empty.svg"}
      />
      <h6 className="EmptyScreen__message">{message || defaultMessage}</h6>
    </div>
  );
}

export default EmptyScreen;
