import React from "react";
import Button from "../../../components/Button/Button";
import PageTitle from "../../../components/PageTitle/PageTitle";

import "./ModeratorSignup.scss";

function ModeratorSignup() {
  return (
    <div className="ModeratorSignup">
      <PageTitle title="Moderator Sign Up" />
      <textarea placeholder="Why do you want to be a moderator?" />
      <Button> Submit </Button>
    </div>
  );
}

export default ModeratorSignup;
