import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  matchPath,
  useLocation,
} from "react-router-dom";
import SearchPage from "./pages/AppScreens/SearchPage/SearchPage";
import TrendingPage from "./pages/AppScreens/TrendingPage/TrendingPage";
import AddArticle from "./pages/AppScreens/AddArticle/AddArticle";
import Homepage from "./pages/AppScreens/Homepage/Homepage";
import MyPosts from "./pages/AppScreens/MyPosts/MyPosts";
import ModeratorSignup from "./pages/AppScreens/ModeratorSignup/ModeratorSignup";
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import { Toaster } from "react-hot-toast";
import { useStateValue } from "./data_layer/store";
import { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import Profile from "./pages/AppScreens/Profile/Profile";
import PrivateRoute from "./util-components/PrivateRoute";
import Confirm from "./pages/Auth/Confirm/Confirm";
import ShareModal from "./pages/AppScreens/Homepage/sub-components/InteractionPanel/sub-components/ShareModal/ShareModal";
import PrivacyPolicy from "./pages/AppScreens/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/AppScreens/TermsConditions/TermsConditions";
import About from "./pages/AppScreens/About/About";
import GetConfirmationModal from "./components/modals/GetConfirmation/GetConfirmation";
import CategoryPosts from "./pages/AppScreens/CategoryPosts/CaregoryPosts";

const RipleApp = ({ firstPath }) => (
  <div className="App">
    <div className="App__Scaffold">
      <Switch>
        {/* Auth screens */}
        <Route exact path="/auth/register" component={Register} />
        <Route exact path="/auth/login" component={Login} />
        <Route exact path="/auth/confirm/:token" component={Confirm} />
        <Route path="auth/*">
          <Redirect to="/auth/login" />
        </Route>
        {/* main Screens */}
        <Route
          exact
          path={["/news/:newsId", "/news", "/"]}
          component={Homepage}
        />
        <Route exact path="/trending" component={TrendingPage} />
        <PrivateRoute exact path="/add-article" component={AddArticle} />
        <PrivateRoute exact path="/search" component={SearchPage} />
        <PrivateRoute exact path="/profiles/:userId" component={Profile} />
        {/* other pages */}
        <PrivateRoute exact path="/profile/my-posts" component={MyPosts} />
        <PrivateRoute
          exact
          path="/category-posts/:category"
          component={CategoryPosts}
        />
        <PrivateRoute
          exact
          path="/profile/moderator"
          component={ModeratorSignup}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms" component={TermsConditions} />
        <Route exact path="/contact" component={About} />
      </Switch>
    </div>
    <Navbar firstPath={firstPath} />
  </div>
);

// const ripleAuth = () => (
//   <Switch>
//     {/* Auth screens */}
//     <Route exact path="/auth/register" component={Register} />
//     <Route exact path="/auth/login" component={Login} />
//     <Route path="*">
//       <Redirect to="/auth/login" />
//     </Route>
//   </Switch>
// );

function App() {
  const [{ user }, dispatch] = useStateValue();
  const [firstPath, setFirstPath] = useState(window.location.pathname);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setUserConfirmed = () => {
    localStorage.setItem("repeatingUser", true);

    setIsModalOpen(false);
  };
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (!user) {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));

      // alert(firstPath);

      if (token) {
        dispatch({
          type: "SET_USER",
          payload: { token, user },
        });
      }
    }
  }, [user]);

  useEffect(() => {
    const repeatingUser = localStorage.getItem("repeatingUser");

    if (!repeatingUser && firstPath !== "/terms") {
      setIsModalOpen(true);
    }
  }, [firstPath]);
  return (
    <Router>
      <GetConfirmationModal
        title="Do you agree?"
        open={isModalOpen}
        hideClose
        confirm={setUserConfirmed}
      >
        <p>
          please agree to our{" "}
          <a href="/terms" onClick={closeModal}>
            terms and conditions
          </a>{" "}
          before entering the website
        </p>
      </GetConfirmationModal>
      <RipleApp firstPath={firstPath} />
      <Toaster
        position="bottom-center"
        reverseOrder={true}
        containerStyle={{ marginBottom: "3.5rem" }}
      />
    </Router>
  );
}

export default App;
