import axios from "axios";
import React, { useEffect, useState } from "react";
import Articles from "../../../components/Articles/Articles";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Tabs from "../../../components/Tabs/Tabs";
import { endPoints } from "../../../config/api";
import { useStateValue } from "../../../data_layer/store";
import { doGet } from "../../../services/apiCalls";

import "./TrendingPage.scss";
import OverlayNewsScroll from "../../../components/OverlayNewsScroll/OverlayNewsScroll";
import Scrollbars from "react-custom-scrollbars";
import Scrollbar from "../../../util-components/Scrollbar";

const tabItems = [
  { title: "Global" },
  { title: "National" },
  // { title: "Regional" },
];

function TrendingPage() {
  const [tab, setTab] = useState(0);
  const [{ loading, user }, dispatch] = useStateValue();
  const [articles, setArticles] = useState([]);
  const [trendingType, setTrendingType] = useState(undefined);
  const [showFull, setShowFull] = useState(false);
  const [articleNo, setArticleNo] = useState(0);

  const closeFullView = () => setShowFull(false);
  const openFullView = (idx) => {
    setShowFull(true);
    setArticleNo(idx);
  };

  const setAppLoading = (loadingState) => {
    dispatch({
      type: "SET_LOADING",
      payload: loadingState,
    });
  };

  const changeTabTo = (i) => {
    setTrendingType(tabItems[i]?.title?.toLowerCase());
    setTab(i);
  };

  useEffect(() => {
    setAppLoading(true);
    doGet(endPoints.getArticles, { params: { type: trendingType } })
      .then((res) => {
        if (!res) return;
        const { data } = res;
        const { articles } = data;
        setArticles(articles);
      })
      .finally(() => setAppLoading(false));

    return () => {
      // cleanup
    };
  }, [trendingType, tab]);

  return (
    <Scrollbar style={{ height: "100%" }}>
      <div className="TrendingPage">
        <PageTitle title={"Trending"} />
        {/* <Tabs tabItems={tabItems} controller={changeTabTo} activeTabIdx={tab} /> */}
        <Articles
          articles={articles}
          canDisplayEmpty={!loading}
          openFullView={openFullView}
        />

        {showFull && (
          <OverlayNewsScroll
            articles={articles}
            user={user}
            hasInteraction
            scrollTo={articleNo}
            close={closeFullView}
          />
        )}
      </div>
    </Scrollbar>
  );
}

export default TrendingPage;
