import React from "react";
import reactDom from "react-dom";
import PageTitle from "../PageTitle/PageTitle";

import "./NotificationShade.scss";

function NotificationShade({ notifications, show }) {
  return reactDom.createPortal(
    <div
      className={`NotificationShade NotificationShade--${
        show ? "open" : "closed"
      }`}
    >
      <PageTitle title="Notifications" />

      {notifications && notifications.length !== 0 ? (
        <div className="NotificationShade__content">
          {notifications?.map((notification) => (
            <p>{notification.title}</p>
          ))}
        </div>
      ) : (
        <div className="NotificationShade__noContent">No notifications</div>
      )}
    </div>,
    document.getElementById("portal")
  );
}

export default NotificationShade;
