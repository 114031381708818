import React, { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button/Button";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { endPoints } from "../../../config/api";
import { useStateValue } from "../../../data_layer/store";
import { doPost } from "../../../services/apiCalls";
import AuthView from "../components/AuthView/AuthView";
import SwitchAuth from "../components/SwitchAuth/SwitchAuth";

import "./Register.scss";

let history;

const register = (email, name, password, confirmPassword, dispatch) => {
  if (email && password && confirmPassword && name) {
    if (password !== confirmPassword)
      return toast.error("Passwords do not match");
    const payload = { email, name, password, confirmPassword };

    const toastId = "reg";

    toast.loading("Signing up", { id: toastId });

    doPost(endPoints.postRegister, payload, { toastId }).then((res) => {
      if (!res) return;
      const { data } = res;
      if (!data.success)
        return toast.error("Something went wrong", { id: toastId });

      const { token, user } = data;

      if (token && user) {
        toast.success(data?.message, { id: toastId });

        dispatch({
          type: "SET_USER",
          payload: { ...{ token }, ...user },
        });

        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        history.push("/");
      }
    });
  } else {
    toast.error("Fields cannot be empty");
  }
};

function Register() {
  const [{}, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  history = useHistory();
  return (
    <AuthView>
      <div className="Register">
        <PageTitle title="Sign Up" />
        <SwitchAuth
          switcherText="Already have an account?"
          linkText="Sign In"
          route="/auth/login"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          onClick={() =>
            register(email, name, password, confirmPassword, dispatch)
          }
        >
          Sign Up
        </Button>
      </div>
    </AuthView>
  );
}

export default Register;
