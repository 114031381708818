import React from "react";
import { Link } from "react-router-dom";
import ShadowScrollbars from "../../util-components/ShadowScollbars";

import "./NewsCard.scss";

const HeroImageCard = ({ src }) => (
  <div
    className="HeroImageCard"
    style={{ backgroundImage: `url(${src})` }}
  ></div>
);

function NewsCard({ article, onClick, expand }) {
  const { title, body, coverImg, owner } = article;

  return (
    <div className="NewsCard" onClick={onClick}>
      <HeroImageCard src={coverImg} />
      <h3 className="NewsCard__headline">{title}</h3>
      <ShadowScrollbars
        style={{ minHeight: `30%`, maxHeight: `45%`, height: `50%` }}
      >
        <p className="NewsCard__body">{body}</p>
      </ShadowScrollbars>
      {owner?.name && (
        <p className="NewsCard__credit">
          courtesy of <Link to={`/profiles/${owner._id}`}>{owner.name}</Link>
        </p>
      )}
    </div>
  );
}

export default NewsCard;
