import React, { useEffect, useState } from "react";

// styles
import "./InteractionPanel.scss";

// icons

// like
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbUpAltRoundedIcon from "@material-ui/icons/ThumbUpAltRounded";

// dislike
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import ThumbDownRoundedIcon from "@material-ui/icons/ThumbDownRounded";

// comment
import ChatBubbleOutlineRoundedIcon from "@material-ui/icons/ChatBubbleOutlineRounded";

// share
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import toast from "react-hot-toast";
import { doGet, doPut } from "../../../../../services/apiCalls";
import { endPoints } from "../../../../../config/api";
import Comments from "./sub-components/Comments/Comments";
import { useStateValue } from "../../../../../data_layer/store";

import ShareModal from "./sub-components/ShareModal/ShareModal";

function InteractionPanel({ article, userId }) {
  const [isLiked, setIsLiked] = useState(false);
  const [prevIsLiked, setPrevIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [prevIsDisliked, setPrevIsDisliked] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);

  const [{ loading, user }, dispatch] = useStateValue();

  const setAppLoading = (loadingState) => {
    dispatch({
      type: "SET_LOADING",
      payload: loadingState,
    });
  };

  const onComment = () => {
    setShowComments(true);
    doGet(endPoints.getComments, { params: { articleId: article._id } }).then(
      ({ data }) => {
        if (!data.success) toast.error("Error loading comments");
        setComments(data.comments);
        setAppLoading(false);
      }
    );
  };

  const closeComments = () => setShowComments(false);

  const onClickLike = () => {
    setIsLiked((prevState) => {
      setPrevIsLiked(prevState);
      return !prevState;
    });
    setIsDisliked(false);
  };
  const onClickDislike = () => {
    setIsDisliked((prevState) => {
      setPrevIsDisliked(prevState);
      return !prevState;
    });
    setIsLiked(false);
  };

  useEffect(() => {
    if (article.likes.includes(userId)) {
      setIsLiked(true);
      setPrevIsLiked(true);
    }
    if (article.dislikes.includes(userId)) {
      setIsDisliked(true);
      setPrevIsDisliked(true);
    }
  }, []);

  useEffect(() => {
    if (isDisliked === prevIsDisliked && isLiked === prevIsLiked) return;
    let event = "";

    if (prevIsDisliked && !isDisliked) event = "undislike";
    if (prevIsLiked && !isLiked) event = "unlike";
    if (!prevIsDisliked && isDisliked) event = "dislike";
    if (!prevIsLiked && isLiked) event = "like";

    const payload = {
      eventType: event,
      userId,
      articleId: article._id,
    };

    doPut(endPoints.putArticle, payload);
  }, [isLiked, isDisliked]);

  const { likes } = article;

  const onShare = () => {
    setShowShareModal(true);
  };

  const closeShareModal = () => setShowShareModal(false);

  return userId ? (
    <>
      <div className="InteractionPanel">
        <div className="InteractionPanel__action" onClick={onClickLike}>
          {isLiked ? <ThumbUpAltRoundedIcon /> : <ThumbUpAltOutlinedIcon />}
          <p>{likes.length + (isLiked === true && 1)}</p>
        </div>
        <div className="InteractionPanel__action" onClick={onClickDislike}>
          {isDisliked ? <ThumbDownRoundedIcon /> : <ThumbDownOutlinedIcon />}
          <p>Dislike</p>
        </div>
        <div className="InteractionPanel__action" onClick={onComment}>
          <ChatBubbleOutlineRoundedIcon />
          <p>Comment</p>
        </div>
        <div className="InteractionPanel__action" onClick={onShare}>
          <ShareOutlinedIcon />
          <p>Share</p>
        </div>
        <Comments
          comments={comments}
          show={showComments}
          onClose={closeComments}
          loading={loading}
          user={{
            _id: user?._id,
            owner: { name: user.name, profilePic: user.profilePic },
          }}
          articleId={article._id}
        />
      </div>
      {showShareModal && (
        <ShareModal articleId={article?._id} onClose={closeShareModal} />
      )}
    </>
  ) : (
    <>
      <div className="InteractionPanel">
        <div className="InteractionPanel__action" onClick={onShare}>
          <ShareOutlinedIcon />
          <p>Share</p>
        </div>
      </div>
      {showShareModal && (
        <ShareModal articleId={article?._id} onClose={closeShareModal} />
      )}
    </>
  );
}

export default InteractionPanel;
