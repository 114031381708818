import { ButtonBase } from "@material-ui/core";
import React from "react";

import "./MenuItem.scss";

function MenuItem({ title, Leading, Trailing, onClick }) {
  return (
    <ButtonBase>
      <div className="MenuItem" onClick={() => onClick?.()}>
        {Leading && (
          <div className="MenuItem__leadingIcon">
            <Leading />
          </div>
        )}
        <p className="MenuItem__title">{title}</p>
        {Trailing && (
          <div className="MenuItem__trailingIcon">
            <Trailing />
          </div>
        )}
      </div>
    </ButtonBase>
  );
}

export default MenuItem;
