import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useStateValue } from "../data_layer/store";
import GetConfirmationModal from "../components/modals/GetConfirmation/GetConfirmation";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [{ user }, dispatch] = useStateValue();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [userConfirm, setUserConfirm] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  const confirmAction = () => setUserConfirm(true);

  useEffect(() => {
    if (!userConfirm && !isModalOpen) {
      history.goBack();
    }
    return () => {
      // cleanup
    };
  }, [isModalOpen]);

  const isAuthenticated = !!user;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <>
            {userConfirm ? (
              <Redirect to="/auth/login" />
            ) : (
              <GetConfirmationModal
                title="Sign In Required"
                open={isModalOpen}
                close={closeModal}
                confirm={confirmAction}
              >
                <p>You need to sign in to complete this action</p>
              </GetConfirmationModal>
            )}
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
