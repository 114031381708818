import React from "react";
import reactDom from "react-dom";

import "./ShareModal.scss";

// share icons
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";

import PageTitle from "../../../../../../../components/PageTitle/PageTitle";

// icons
import LinkIcon from "@material-ui/icons/Link";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import RedditIcon from "@material-ui/icons/Reddit";
import TelegramIcon from "@material-ui/icons/Telegram";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import toast from "react-hot-toast";

function ShareModal({ articleId, onClose }) {
  const url = `${window.location.origin}/news/${articleId || ""}`;

  const onShare = () => {
    navigator.clipboard.writeText(url);
    toast.success("Link copied");
    onClose();
  };

  return reactDom.createPortal(
    <>
      <div className="ShareModal__overlay" onClick={onClose}></div>
      <div className="ShareModal">
        <div className="ShareModal__header" onClick={onClose}>
          <PageTitle title="Share" /> <KeyboardArrowDownRoundedIcon />
        </div>
        <div className="ShareModal__shareIcons">
          <div className="ShareModal__shareAction" onClick={onShare}>
            <LinkIcon />
            <p className="ShareModal__shareActionLabel">Copy Link</p>
          </div>
          <div className="ShareModal__shareAction">
            <WhatsappShareButton url={url}>
              <WhatsAppIcon />
            </WhatsappShareButton>
            <p className="ShareModal__shareActionLabel">WhatsApp</p>
          </div>
          <div className="ShareModal__shareAction">
            <FacebookShareButton url={url}>
              <FacebookIcon />
            </FacebookShareButton>
            <p className="ShareModal__shareActionLabel">Facebook</p>
          </div>
          <div className="ShareModal__shareAction">
            <TwitterShareButton url={url}>
              <TwitterIcon />
            </TwitterShareButton>
            <p className="ShareModal__shareActionLabel">Twitter</p>
          </div>
          <div className="ShareModal__shareAction">
            <LinkedinShareButton url={url}>
              <LinkedInIcon />
            </LinkedinShareButton>
            <p className="ShareModal__shareActionLabel">Linkedin</p>
          </div>
          <div className="ShareModal__shareAction">
            <RedditShareButton url={url}>
              <RedditIcon />
            </RedditShareButton>
            <p className="ShareModal__shareActionLabel">Reddit</p>
          </div>
          <div className="ShareModal__shareAction">
            <TelegramShareButton url={url}>
              <TelegramIcon />
            </TelegramShareButton>
            <p className="ShareModal__shareActionLabel">Telegram</p>
          </div>
          <div className="ShareModal__shareAction">
            <EmailShareButton url={url}>
              <MailOutlineIcon />
            </EmailShareButton>
            <p className="ShareModal__shareActionLabel">Email</p>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
}

export default ShareModal;
