import React from "react";

// components from MUI
import ButtonBase from "@material-ui/core/ButtonBase";

// styles
import "./Button.scss";

// helpers
import { getAppearance } from "../../../src/helpers/styleHelpers/getAppearance";

// Appearance can be one of "primary", "secondary", "disabled"
function Button({ onClick, link, appearance, children }) {
  return (
    <div className="Button__wrap">
      <ButtonBase>
        <a href={link}>
          <div
            onClick={() => onClick?.()}
            className={`Button Button--${getAppearance(
              appearance || "primary"
            )}`}
          >
            {children}
          </div>
        </a>
      </ButtonBase>
    </div>
  );
}

export default Button;
