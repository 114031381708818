import React, { useEffect, useState } from "react";
import Articles from "../../../components/Articles/Articles";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { endPoints } from "../../../config/api";
import { useStateValue } from "../../../data_layer/store";
import { doGet } from "../../../services/apiCalls";

import OverlayNewsScroll from "../../../components/OverlayNewsScroll/OverlayNewsScroll";

import "./CategoryPosts.scss";
import Scrollbar from "../../../util-components/Scrollbar";
import { useParams } from "react-router-dom";

function CategoryPosts() {
  const [{ loading, user }, dispatch] = useStateValue();
  const [articles, setArticles] = useState([]);

  const [showFull, setShowFull] = useState(false);
  const [articleNo, setArticleNo] = useState(0);

  const { category } = useParams();

  const closeFullView = () => setShowFull(false);
  const openFullView = (idx) => {
    setShowFull(true);
    setArticleNo(idx);
  };

  const setAppLoading = (loadingState) => {
    dispatch({
      type: "SET_LOADING",
      payload: loadingState,
    });
  };

  useEffect(() => {
    setAppLoading(true);
    doGet(endPoints.getArticles, { params: { category } })
      .then((res) => {
        if (!res) return;
        const { data } = res;
        const { articles } = data;
        setArticles(articles);
      })
      .finally(() => setAppLoading(false));

    return () => {
      // cleanup
    };
  }, []);

  return (
    <Scrollbar>
      <div className="CategoryPosts">
        <PageTitle title="Posts by Category" />
        <Articles
          articles={articles}
          canDisplayEmpty={!loading}
          openFullView={openFullView}
        />
        {showFull && (
          <OverlayNewsScroll
            articles={articles}
            user={user}
            hasInteraction
            scrollTo={articleNo}
            close={closeFullView}
          />
        )}
      </div>
    </Scrollbar>
  );
}

export default CategoryPosts;
