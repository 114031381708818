import React, { useEffect } from "react";

import "./AuthView.scss";

function AuthView({ children }) {
  useEffect(() => {
    document.getElementById("navigation").style.opacity = 0;
    document.getElementById("navigation").style.marginBottom = "-20px";
    document.getElementById("navigation").style.pointerEvents = "none";
    return () => {
      document.getElementById("navigation").style.opacity = 1;
      document.getElementById("navigation").style.marginBottom = "0";
      document.getElementById("navigation").style.pointerEvents = "auto";
    };
  }, []);

  return (
    <div className="AuthView">
      <div className="AuthView__container">
        <div className="AuthView__brandingWeb">
          <img src="/logos/logoMonoLight.png" alt="" />
        </div>
        <div className="AuthView__brandingMobile">
          <img src="/logos/logo.png" alt="Riple logo" />
        </div>
        <div className="AuthView__formContainer">{children}</div>
      </div>
    </div>
  );
}

export default AuthView;
