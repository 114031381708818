import React from "react";

// icons
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import SupervisorAccountRoundedIcon from "@material-ui/icons/SupervisorAccountRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import PolicyRoundedIcon from "@material-ui/icons/PolicyRounded";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";

import { useHistory } from "react-router";
import MenuItem from "../../../MenuItem/MenuItem";

import "./ProfileMenu.scss";
import { useStateValue } from "../../../../data_layer/store";
import toast from "react-hot-toast";

let history;
let globalDispatch;

const logout = () => {
  globalDispatch({
    type: "SET_USER",
    payload: null,
  });

  localStorage.removeItem("token");
  localStorage.removeItem("user");
  toast.success("Signed out. Browsing as anonymous");
  history.push("/");
};

const menuItems = [
  {
    title: "My posts",
    Icon: DescriptionRoundedIcon,
    onClick: () => history.push("/profile/my-posts"),
  },
  // {
  //   title: "Basic Information",
  //   Icon: InfoRoundedIcon,
  //   onClick: () => history.push("/profile/info"),
  // },
  // {
  //   title: "Settings",
  //   Icon: SettingsRoundedIcon,
  //   onClick: () => history.push("/profile/settings"),
  // },
  {
    title: "Become a moderator",
    Icon: SupervisorAccountRoundedIcon,
    onClick: () => history.push("/profile/moderator"),
  },
  {
    title: "Privacy Policy",
    Icon: PolicyRoundedIcon,
    onClick: () => history.push("/privacy-policy"),
  },
  {
    title: "Terms & Conditions",
    Icon: FindInPageRoundedIcon,
    onClick: () => history.push("/terms"),
  },
  {
    title: "Contact",
    Icon: InfoRoundedIcon,
    onClick: () => history.push("/contact"),
  },
  {
    title: "Sign Out",
    Icon: ExitToAppRoundedIcon,
    onClick: () => logout(),
  },
];

function ProfileMenu({ isOpen, closeMenu, closeOnNav }) {
  history = useHistory();
  const [{}, dispatch] = useStateValue();

  globalDispatch = dispatch;

  if (!isOpen) return null;

  return (
    <>
      <div className="ProfileMenu__overlay" onClick={closeMenu}></div>
      <div className="ProfileMenu">
        {menuItems?.map((menuItem, idx) => (
          <div onClick={closeOnNav} key={idx}>
            <MenuItem
              Leading={menuItem.Icon}
              title={menuItem.title}
              onClick={menuItem.onClick}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default ProfileMenu;
