import { Skeleton } from "@material-ui/lab";
import React from "react";

import "./NewsCardSkeleton.scss";

function NewsCardSkeleton() {
  return (
    <div className="NewsCardSkeleton">
      <div className="NewsCardSkeleton__coverImg">
        <Skeleton variant="rect" animation="wave" width="100%" height="100%" />
      </div>
      <Skeleton animation="wave" height="3rem" />
      <br />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" width="60%" />
      <Skeleton animation="wave" width="80%" />
    </div>
  );
}

export default NewsCardSkeleton;
