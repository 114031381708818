import React, { useEffect } from "react";
import CardScollView, { CardView } from "../CardScollView/CardScollView";
import NewsCard from "../NewsCard/NewsCard";
import InteractionPanel from "../../pages/AppScreens/Homepage/sub-components/InteractionPanel/InteractionPanel";
import "./OverlayNewsScroll.scss";
import reactDom from "react-dom";
import { CloseRounded } from "@material-ui/icons";

function OverlayNewsScroll({
  articles,
  user,
  hasInteraction,
  scrollTo,
  close,
}) {
  const refs = articles.map((_) => React.createRef());

  useEffect(() => {
    if (scrollTo && scrollTo > 0) refs[scrollTo]?.current?.scrollIntoView();
  }, [scrollTo]);

  return reactDom.createPortal(
    <div className="OverlayNewsScroll">
      <div className="OverlayNewsScroll__close" onClick={close}>
        <CloseRounded />
      </div>
      <CardScollView isArticles={articles.length}>
        {articles?.map((article, idx) => (
          <CardView key={idx} refId={refs[idx]}>
            <NewsCard article={article} />
            {hasInteraction && (
              <InteractionPanel article={article} userId={user?._id} />
            )}
          </CardView>
        ))}
      </CardScollView>
    </div>,
    document.getElementById("portal")
  );
}

export default OverlayNewsScroll;
