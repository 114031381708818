import React, { useState } from "react";
import NotificationsNoneRoundedIcon from "@material-ui/icons/NotificationsNoneRounded";
import NotificationShade from "../NotificationShade/NotificationShade";

import "./Header.scss";

function Header({}) {
  const [showNotification, setShowNotification] = useState(false);

  // const hideNotification = () => setShowNotification(false);
  const toggleNotification = () => setShowNotification(!showNotification);

  return (
    <div className="Header">
      <div className="Header__logo">
        <img src="/logos/logo.png" alt="riple logo" />
      </div>
      <div className="Header__notifIconWrap" onClick={toggleNotification}>
        <NotificationsNoneRoundedIcon />
      </div>
      {<NotificationShade show={showNotification} />}
    </div>
  );
}

export default Header;
