import React, { useEffect } from "react";
import SubSection from "../../../components/WrtiteUp/sub-components/SubSection/SubSection";
import WrtiteUp from "../../../components/WrtiteUp/WrtiteUp";
import Button from "../../../components/Button/Button";
import { useHistory } from "react-router";

import "./TermsConditions.scss";

function TermsConditions() {
  const history = useHistory();
  const agreeTerms = () => {
    localStorage.setItem("repeatingUser", true);
    history.push("/");
  };

  useEffect(() => {
    document.getElementById("navigation").style.opacity = 0;
    document.getElementById("navigation").style.marginBottom = "-20px";
    document.getElementById("navigation").style.pointerEvents = "none";
    return () => {
      document.getElementById("navigation").style.opacity = 1;
      document.getElementById("navigation").style.marginBottom = "0";
      document.getElementById("navigation").style.pointerEvents = "auto";
    };
  }, []);

  return (
    <div className="TermsConditions">
      <WrtiteUp title="Terms	&#38; Condition">
        <SubSection title="">
          <p className="WriteUp__paragraph">
            The Riple website located at Riple.org is a copyrighted work
            belonging to Riple. Certain features of the Site may be subject to
            additional guidelines, terms, or rules, which will be posted on the
            Site in connection with such features.
          </p>
          <p className="WriteUp__paragraph">
            All such additional terms, guidelines, and rules are incorporated by
            reference into these Terms.
          </p>
          <p className="WriteUp__paragraph">
            These Terms of Use described the legally binding terms and
            conditions that oversee your use of the Site. BY LOGGING INTO THE
            SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you represent
            that you have the authority and capacity to enter these Terms. YOU
            SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU
            DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO NOT LOG INTO
            AND/OR USE THE SITE.
          </p>
          <p className="WriteUp__paragraph">
            These terms require the use of arbitration Section 10.2 on an
            individual basis to resolve disputes and limit the remedies
            available to you in the event of a dispute.
          </p>
        </SubSection>
        <br />
        <br />
        <h3>Accounts</h3>
        <br />
        <SubSection title="">
          <p className="WriteUp__paragraph">
            <b>Account Creation.</b> For you to use the Site, you have to start
            an account and provide information about yourself. You warrant that:
            (a) all required registration information you submit is truthful,
            up-to-date and accurate; (b) you will maintain the accuracy of such
            information. You may delete your Account at any time by following
            the instructions on the Site. Company may suspend or terminate your
            Account in accordance with Section.
          </p>
          <p className="WriteUp__paragraph">
            <b> Account Responsibilities.</b> You are responsible for
            maintaining the confidentiality of your Account login information
            and are fully responsible for all activities that occur under your
            Account. You approve to immediately notify the Company of any
            unauthorized use, or suspected unauthorized use of your Account.
            Company cannot and will not be liable for any loss or damage arising
            from your failure to comply with the above requirements.
          </p>
        </SubSection>
        <br />
        <br />
        <h3>Access to the Site</h3>
        <br />
        <SubSection title="">
          <p className="WriteUp__paragraph">
            <b>Subject to these Terms.</b> Company grants you a
            non-transferable, non-exclusive, revocable, limited license to
            access the Site solely for your own personal, noncommercial use.
          </p>
          <p className="WriteUp__paragraph">
            <b>Certain Restrictions.</b> The rights approved to you in these
            Terms are subject to the following restrictions: (a) you shall not
            sell, rent, lease, transfer, assign, distribute, host, or otherwise
            commercially exploit the Site; (b) you shall not change, make
            derivative works of, disassemble, reverse compile or reverse
            engineer any part of the Site; (c) you shall not access the Site in
            order to build a similar or competitive website; and (d) except as
            expressly stated herein, no part of the Site may be copied,
            reproduced, distributed, republished, downloaded, displayed, posted
            or transmitted in any form or by any means unless otherwise
            indicated, any future release, update, or other addition to
            functionality of the Site shall be subject to these Terms. All
            copyright and other proprietary notices on the Site must be retained
            on all copies thereof.
          </p>
          <p className="WriteUp__paragraph">
            <b>No Support or Maintenance.</b> You agree that Company will have
            no obligation to provide you with any support in connection with the
            Site.
          </p>
          <p className="WriteUp__paragraph">
            Company reserves the right to change, suspend, or cease the Site
            with or without notice to you. You approved that Company will not be
            held liable to you or any third-party for any change, interruption,
            or termination of the Site or any part.
          </p>
          Excluding any User Content that you may provide, you are aware that
          all the intellectual property rights, including copyrights, patents,
          trademarks, and trade secrets, in the Site and its content are owned
          by Company or Company's suppliers. Note that these Terms and access to
          the Site do not give you any rights, title or interest in or to any
          intellectual property rights, except for the limited access rights
          expressed in Section 2.1. Company and its suppliers reserve all rights
          not granted in these Terms.
        </SubSection>
        <br />
        <br />
        <h3>User Content</h3>
        <br />
        <SubSection title="">
          <p className="WriteUp__paragraph">
            <b> User Content.</b> “User Content” means any and all information
            and content that a user submits to the Site. You are exclusively
            responsible for your User Content. You bear all risks associated
            with use of your User Content. You hereby certify that your User
            Content does not violate our Acceptable Use Policy. You may not
            represent or imply to others that your User Content is in any way
            provided, sponsored or endorsed by Company. Because you alone are
            responsible for your User Content, you may expose yourself to
            liability. Company is not obliged to backup any User Content that
            you post; also, your User Content may be deleted at any time without
            prior notice to you. You are solely responsible for making your own
            backup copies of your User Content if you desire.
          </p>
          <p className="WriteUp__paragraph">
            You hereby grant to Company an irreversible, nonexclusive,
            royalty-free and fully paid, worldwide license to reproduce,
            distribute, publicly display and perform, prepare derivative works
            of, incorporate into other works, and otherwise use and exploit your
            User Content, and to grant sublicenses of the foregoing rights,
            solely for the purposes of including your User Content in the Site.
            You hereby irreversibly waive any claims and assertions of moral
            rights or attribution with respect to your User Content.
          </p>
          <p className="WriteUp__paragraph">
            <b>Acceptable Use Policy.</b> The following terms constitute our
            “Acceptable Use Policy”: You agree not to use the Site to collect,
            upload, transmit, display, or distribute any User Content (i) that
            violates any third-party right or any intellectual property or
            proprietary right; (ii) that is unlawful, harassing, abusive,
            tortious, threatening, harmful, invasive of another's privacy,
            vulgar, defamatory, false, intentionally misleading, trade libelous,
            pornographic, obscene, patently offensive, promotes racism, bigotry,
            hatred, or physical harm of any kind against any group or
            individual; (iii) that is harmful to minors in any way; or (iv) that
            is in violation of any law, regulation, or obligations or
            restrictions imposed by any third party.
          </p>
          <p className="WriteUp__paragraph">
            In addition, you agree not to: (i) upload, transmit, or distribute
            to or through the Site any software intended to damage or alter a
            computer system or data; (ii) send through the Site unsolicited or
            unauthorized advertising, promotional materials, junk mail, spam,
            chain letters, pyramid schemes, or any other form of duplicative or
            unsolicited messages; (iii) use the Site to harvest, collect, gather
            or assemble information or data regarding other users without their
            consent; (iv) interfere with, disrupt, or create an undue burden on
            servers or networks connected to the Site, or violate the
            regulations, policies or procedures of such networks; (v) attempt to
            gain unauthorized access to the Site, whether through password
            mining or any other means; (vi) harass or interfere with any other
            user's use and enjoyment of the Site; or (vi) use software or
            automated agents or scripts to produce multiple accounts on the
            Site, or to generate automated searches, requests, or queries to the
            Site.
          </p>
          <p className="WriteUp__paragraph">
            We reserve the right to review any User Content, and to investigate
            and/or take appropriate action against you in our sole discretion if
            you violate the Acceptable Use Policy or any other provision of
            these Terms or otherwise create liability for us or any other
            person. Such action may include removing or modifying your User
            Content, terminating your Account in accordance with Section 8,
            and/or reporting you to law enforcement authorities.
          </p>
          <p className="WriteUp__paragraph">
            If you provide Company with any feedback or suggestions regarding
            the Site, you hereby assign to Company all rights in such Feedback
            and agree that Company shall have the right to use and fully exploit
            such Feedback and related information in any manner it believes
            appropriate. Company will treat any Feedback you provide to Company
            as non-confidential and non-proprietary.
          </p>
          <p className="WriteUp__paragraph">
            You agree to indemnify and hold Company and its officers, employees,
            and agents harmless, including costs and attorneys' fees, from any
            claim or demand made by any third-party due to or arising out of (a)
            your use of the Site, (b) your violation of these Terms, (c) your
            violation of applicable laws or regulations or (d) your User
            Content. Company reserves the right to assume the exclusive defense
            and control of any matter for which you are required to indemnify
            us, and you agree to cooperate with our defense of these claims. You
            agree not to settle any matter without the prior written consent of
            Company. Company will use reasonable efforts to notify you of any
            such claim, action or proceeding upon becoming aware of it.
          </p>
        </SubSection>
      </WrtiteUp>
      <div className="TermsConditions__agreeButton">
        <Button onClick={agreeTerms}>I Agree </Button>
      </div>
    </div>
  );
}

export default TermsConditions;
