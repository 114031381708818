import React from "react";

// components
import SubSectionLabel from "../../../../components/SubSectionLabel/SubSectionLabel";

// icons
import FeaturedPlayListRoundedIcon from "@material-ui/icons/FeaturedPlayListRounded";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import GradeRoundedIcon from "@material-ui/icons/GradeRounded";
import { useHistory } from "react-router";

let history;

const categories = [
  {
    label: "All",
    icon: <FeaturedPlayListRoundedIcon />,
    onClick: () => history.push("/news"),
  },
  { label: "Top", icon: <GradeRoundedIcon /> },
  { label: "Bookmarked", icon: <BookmarkRoundedIcon /> },
];

function CategoriesSection() {
  history = useHistory();
  return (
    <>
      <SubSectionLabel title={"Categories"} />
      <div className="CategoriesSection__categories">
        {categories.map(({ label, icon, onClick }, idx) => (
          <div
            key={idx}
            className="CategoriesSection__category"
            onClick={onClick}
          >
            {icon}
            <p className="CategoriesSection__categoryLabel">{label}</p>
          </div>
        ))}
      </div>
    </>
  );
}

export default CategoriesSection;
