import React from "react";

// styles
import "./SubSectionLabel.scss";

function SubSectionLabel({ title }) {
  return (
    <div className="SubSectionLabel">
      <h4>{title}</h4>
    </div>
  );
}

export default SubSectionLabel;
