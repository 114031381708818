import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { endPoints } from "../../../config/api";
import { useStateValue } from "../../../data_layer/store";
import { doPost } from "../../../services/apiCalls";

import "./Confirm.scss";

function Confirm() {
  const { token } = useParams();
  const history = useHistory();
  const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    doPost(endPoints.postConfirmation, { token }, { headers: {} }).then(
      ({ data }) => {
        toast.success(data.message);

        if (user) {
          user.emailToken = undefined;
          dispatch({
            type: "SET_USER",
            payload: { userVerified: true, ...user },
          });
          history.push("/news");
        } else {
          toast("Please login.");
          history.push("/auth/login");
        }
      }
    );
  }, []);

  return <></>;
}

export default Confirm;
