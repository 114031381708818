import React, { useEffect, useState } from "react";
import { Navitems } from "./NavigationConfigs";

// styles
import "./Navbar.scss";
import { useHistory } from "react-router";
import ProfileMenu from "./sub-components/ProfileMenu/ProfileMenu";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import { LinearProgress } from "@material-ui/core";
import { useStateValue } from "../../data_layer/store";
import { matchPath, useLocation } from "react-router-dom";

let history;

const NavItem = ({ icon, label, controller, idx, isActive }) => (
  <div
    className={`NavItem${isActive ? ` NavItem--active` : ""}`}
    onClick={() => controller?.(idx)}
  >
    {icon}
    <h6 className="NavItem__label"> {label} </h6>
  </div>
);

function Navbar({ controller, activeItemIndex, firstPath }) {
  history = useHistory();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [{ loading, user }, dispatch] = useStateValue();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  history.listen((location) => {
    setCurrentPath(location.pathname);
  });

  const match = matchPath(firstPath, {
    path: "/news/:newId",
    exact: true,
  });

  const [navItemIndex, setNavItemIndex] = useState(0);

  useEffect(() => {
    closeProfileMenu();

    for (const [idx, navItem] of Navitems.entries()) {
      const match = navItem.endPoints.filter((path) =>
        matchPath(location.pathname, {
          path,
          exact: true,
        })
      );
      if (match.length) {
        setNavItemIndex(idx);
        break;
      }
    }

    return () => {
      // cleanup
    };
  }, [navItemIndex, history, currentPath]);

  const setNav = (idx) => {
    setNavItemIndex(idx);
    handleNavActive(idx);
  };

  const handleProfileClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavActive = (idx) => {
    if (idx !== Navitems.length + 1) history.push(Navitems[idx].endPoints[0]);
    if (match && idx === 0) history?.push(match.url);
  };

  // const openProfileMenu = () => setIsMenuOpen(true);

  const closeProfileMenu = () => setIsMenuOpen(false);

  const closeProfileMenuOnNav = () => {
    setIsMenuOpen(false);
    setNavItemIndex(Navitems.length + 1);
  };

  const handleSignPrompt = () => {
    history?.push("/auth/login");
  };

  const NavLogo = () => (
    <div className="Navbar__navLogo">
      <img src="/logos/logo.png" alt="" />
    </div>
  );

  return (
    <div className="Navbar__wrap" id="navigation">
      <ProfileMenu
        isOpen={isMenuOpen}
        closeOnNav={closeProfileMenuOnNav}
        closeMenu={closeProfileMenu}
      />
      {loading && (
        <div className="Navbar__loader">
          <LinearProgress color="secondary" />
        </div>
      )}
      <div className="Navbar">
        <NavLogo />
        {Navitems.map(({ icon, label }, idx) => (
          <NavItem
            key={idx}
            label={label}
            icon={icon}
            controller={setNav}
            isActive={idx === navItemIndex}
            idx={idx}
          />
        ))}
        {!!user && (
          <NavItem
            key={Navitems.length + 1}
            label="Profile"
            icon={<AccountCircleRoundedIcon />}
            controller={handleProfileClick}
            isActive={isMenuOpen}
            idx={Navitems.length + 1}
          />
        )}

        {!user && (
          <NavItem
            key={Navitems.length + 1}
            label="Sign In"
            icon={<LockOpenRoundedIcon />}
            controller={handleSignPrompt}
            idx={Navitems.length + 1}
          />
        )}
      </div>
    </div>
  );
}

export default Navbar;
