import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import SubSectionLabel from "../../../../components/SubSectionLabel/SubSectionLabel";
import { endPoints } from "../../../../config/api";
import { useStateValue } from "../../../../data_layer/store";
import { doGet } from "../../../../services/apiCalls";

const TopicCard = ({ title, imgUrl, onClick }) => (
  <div
    className="TopicCard"
    style={{ backgroundImage: `url('${imgUrl}')` }}
    onClick={onClick}
  >
    <p className="TopicCard__title">{title}</p>
  </div>
);

function TopicSection() {
  const [{ loading }, dispatch] = useStateValue();
  const [topics, setTopics] = useState([]);

  const history = useHistory();

  const setAppLoading = (loadingState) => {
    dispatch({
      type: "SET_LOADING",
      payload: loadingState,
    });
  };

  useEffect(() => {
    setAppLoading(true);
    doGet(endPoints.getTopics)
      .then((res) => {
        if (!res) return;
        const {
          data: { topics },
        } = res;
        setTopics(topics);
      })
      .finally(() => setAppLoading(false));

    return () => {
      // cleanup
    };
  }, []);

  return (
    <div className="TopicSection">
      <SubSectionLabel title="Topics" />
      <div className="TopicSection__topics">
        {topics?.map(({ title, imgUrl, value }, idx) => (
          <TopicCard
            title={title}
            imgUrl={imgUrl}
            key={idx}
            onClick={() => history.push(`/category-posts/${value}`)}
          />
        ))}
      </div>
    </div>
  );
}

export default TopicSection;
