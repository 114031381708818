import React from "react";

// icons
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import WhatshotRoundedIcon from "@material-ui/icons/WhatshotRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";

export const Navitems = [
  {
    label: "Home",
    icon: <HomeRoundedIcon />,
    endPoints: ["/news", "/news/:newsId", "/"],
  },
  {
    label: "Trending",
    icon: <WhatshotRoundedIcon />,
    endPoints: ["/trending"],
  },
  {
    label: "Add Article",
    icon: <AddCircleOutlineRoundedIcon />,
    endPoints: ["/add-article"],
  },
  { label: "Search", icon: <SearchRoundedIcon />, endPoints: ["/search"] },
];
